<template>
    <div class="grid grid-cols-3 gap-4">
        <div v-loading="$wait.is('loading')" class="card text-center" element-loading-spinner="el-custom-spinner">
            <p class="text-lg mb-4 font-semibold">
                {{ totalHours }}
            </p>
            <p>
                {{ $t('clients.total_worked_hours') }}
            </p>
        </div>
        <div v-loading="$wait.is('loading')" class="card text-center" element-loading-spinner="el-custom-spinner">
            <p class="text-lg mb-4 font-semibold">
                {{ totalPlannedHours }}
            </p>
            <p>
                {{ $t('clients.total_planned_hours') }}
            </p>
        </div>
        <div v-loading="$wait.is('loading')" class="card text-center" element-loading-spinner="el-custom-spinner">
            <p class="text-lg mb-4 font-semibold">
                {{ averageRating }}
            </p>
            <p>
                {{ $t('clients.average_rate') }}
            </p>
        </div>
    </div>
</template>
<script>
import sumBy from 'lodash/sumBy';
import meanBy from 'lodash/meanBy';

export default {
    props: {
        historyData: {
            type:    Array,
            default: () => [],
        },
    },

    computed: {
        totalHours() {
            return this.normalizeTime(sumBy(this.historyData, 'duration'));
        },

        totalPlannedHours() {
            return this.normalizeTime(sumBy(this.historyData, 'planned'));
        },

        averageRating() {
            const realRatings = this.historyData.filter(object => object.rate && object.rate.rate > 0);
            const average = meanBy(realRatings, 'rate.rate').toFixed(1);
            return realRatings.length > 0 ? average : 0;
        },
    },
};
</script>
